<script>
const EVENTS = {
  created: 'created',
  mounted: 'mounted',
}
const getEventId = function(id, event) {
  return [id, event].join('--')
}

export default {
  computed: {
    mountedId() {
      return this.$options.name
    },
  },
  created() {
    if (process.client) {
      const event = new CustomEvent(getEventId(this.mountedId, EVENTS.created))
      window.dispatchEvent(event)
    }
  },
  async mounted() {
    await this.$nextTick()

    const event = new CustomEvent(getEventId(this.mountedId, EVENTS.mounted))
    window.dispatchEvent(event)
  },
}
</script>
