import FooterBottom from '~/themes/base/components/shop/FooterBottom/FooterBottom.js'

export default {
  mixins: [FooterBottom],
  data() {
    return {
      copyrightName: null,
      menus: [
        this.$store.state.menu.footer_main_1,
        this.$store.state.menu.footer_main_2,
        this.$store.state.menu.footer_main_3,
        this.$store.state.menu.footer_main_4,
      ],
      notEmptyMenusInFooterMain: null,
    }
  },
  mounted() {
    this.notEmptyMenusInFooterMain = this.menus.filter(menu => menu.menuItems.length > 0).length
    this.copyrightName =
      typeof this.$store.state.globals.footer.copyright_name !== 'undefined'
        ? this.$store.state.globals.footer.copyright_name
        : this.$store.state.globals.contact.businessName
  },
  computed: {
    copyrightYear() {
      return (
        '&copy;&nbsp;' +
        (typeof this.$store.state.globals.footer.copyright_year !== 'undefined' &&
        this.$store.state.globals.footer.copyright_year !== ''
          ? `${this.$store.state.globals.footer.copyright_year}&ndash;${this.currentYear}`
          : this.currentYear)
      )
    },
  },
}
