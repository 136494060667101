import { filter } from 'lodash'
import ContentBuilder from '~/themes/base/components/utils/ContentBuilder'
import LifecycleMixin from '~/mixins/LifecycleMixin'

export default {
  extends: ContentBuilder,
  mixins: [LifecycleMixin],
  beforeMount() {
    this.pushScriptBlocksToHead()
  },
  methods: {
    pushScriptBlocksToHead() {
      const scriptBlocks = filter(this.blocks, block => block.type === 'html' && block.value.startsWith('<script>'))
      scriptBlocks.forEach(block => {
        const id = `content-builder-script-${block.blockUuid}`
        const scriptElement = document.getElementById(id)

        if (scriptElement) return

        let value = block.value
        value = value.replace('<script>', '')
        value = value.replace('</script>', '')

        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.id = id
        script.innerHTML = value
        document.getElementsByTagName('head')[0].appendChild(script)
      })
    },
  },
}
